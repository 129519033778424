import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { usePageSlug } from "../utils"

const SideMenu = () => {

  const slug = usePageSlug()

  const { wpNavigazione } = useStaticQuery(graphql`
      {
          wpNavigazione {
              singola_navigazione {
                  elenco {
                      collegamento {
                          ... on WpSponsorStand {
                              id
                              slug
                          }
                          ... on WpVirtualRoom {
                              id
                              slug
                          }
                      }
                      icona {
                          localFile {
                              publicURL
                          }
                      }
                      titolo
                  }
              }
          }
      }
  `)

  return (
    <div className="pf-side-menu-wrapper">
      {/*<div className="pf-side-menu-group">*/}
      {/*  <h5 className="pf-side-menu-label">Opzioni utente</h5>*/}
      {/*  <ul>*/}
      {/*    <li><a><img src="/img/ico-star.svg" alt="star" />Disconetti</a></li>*/}
      {/*  </ul>*/}
      {/*</div>*/}

      <div className="pf-side-menu-group">
        <h5 className="pf-side-menu-label">Navigazione stanze</h5>
        <ul>
          {
            wpNavigazione.singola_navigazione.elenco.map(({ titolo, icona, collegamento }) => (
              <li key={titolo} className={slug === collegamento.slug ? "active" : ""}>
                <Link to={`/${collegamento.slug}`}>
                  <img src={icona.localFile.publicURL} alt={titolo} /> {titolo}
                </Link>
              </li>
            ))
          }
        </ul>
      </div>

      {/*<div className="pf-side-menu-group">*/}
      {/*  <h5 className="pf-side-menu-label">Sponsor Stands</h5>*/}
      {/*  <ul>*/}
      {/*    <li><Link to={"/sponsor_stand"}><img src="/img/ico-star.svg" alt="star" />Stand Sponsor</Link></li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </div>
  )
}

export default SideMenu
