import React, { useState } from "react"
import SideMenu from "./SideMenu"
import { Col, Drawer, Row } from "antd"
import { useEventValue } from "../hooks/useEventValue"
import { graphql, Link, useStaticQuery } from "gatsby"


const TopBar = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [{ theme }] = useEventValue()

  const { wp } = useStaticQuery(graphql`
      {
          wp {
              opzioniDelTema {
                  opzioni_navigazione {
                      homeEvento {
                          ... on WpVirtualRoom {
                              id
                              slug
                          }
                      }
                      logoEvento {
                          localFile {
                              childImageSharp {
                                  fluid {
                                      src
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  const onClose = () => {
    setShowDrawer(false)
  }

  const styles = {
    container: {
      background: theme.mainBackground,
      height: theme.topBarHeight,
      color: theme.textColor,
      padding: "0 20px"
    },
    drawerContainer: {
      background: "transparent"
    },
    drawer: {
      background: theme.mainBackground,
      borderRadius: "12px 0 0 12px"
    },
    drawerHeader: {
      background: "transparent",
      border: "none",
      paddingBottom: 0
    },
    drawerBody: {
      // background: 'green'
      paddingTop: 0,
    }
  }

  const eventLogo = wp.opzioniDelTema.opzioni_navigazione.logoEvento ? wp.opzioniDelTema.opzioni_navigazione.logoEvento.localFile.childImageSharp.fluid.src : '/img/logo.svg';

  return (
    <>
      <Row align="middle" style={styles.container}>
        <Col span={6}>
          <Col>
            <Link to={`/${wp.opzioniDelTema.opzioni_navigazione.homeEvento.slug}`}>
              <img src={eventLogo}
                   alt="logo" />
            </Link>
          </Col>
        </Col>
        <Col span={18}>
          <Row justify="end">
            <Col>
              <a onClick={() => {
                setShowDrawer(true)
              }}>
                <img src="/img/ico-menu.svg" />
              </a>
            </Col>
          </Row>
        </Col>

      </Row>
      <Drawer
        className="pf-side-bar"
        title={
          <Link to={`/${wp.opzioniDelTema.opzioni_navigazione.homeEvento.slug}`}>
            <img src={eventLogo}
                 alt="logo" />
          </Link>
        }
        placement={"right"}
        closable={true}
        onClose={onClose}
        visible={showDrawer}
        width={250}
        style={styles.drawerContainer}
        drawerStyle={styles.drawer}
        headerStyle={styles.drawerHeader}
        bodyStyle={styles.drawerBody}
      >
        <SideMenu />
      </Drawer>
    </>
  )
}


export default TopBar
