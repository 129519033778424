import React from "react"
import PropTypes from "prop-types"

import TopBar from "./TopBar"
import Docker from "./Docker"
import { useEventValue } from "../hooks/useEventValue"
import LoadingScreen from "./LoadingScreen"

const Layout = ({ children }) => {
  const [{ isLoadingUser }] = useEventValue()

  if (isLoadingUser) {
    return <LoadingScreen />
  }

  return (
    <>
      <TopBar />
      <div>
        {children}
      </div>
      <Docker />
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
