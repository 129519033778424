import React, { useEffect } from "react"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { Col, Row } from "antd"
import { usePageSlug } from "../utils"
import { useLocation } from "@reach/router"

const hideOnMobileColSettings = {
  xs: 0,
  sm: 0,
  md: 24
}

const Docker = () => {
  const location = useLocation()
  const slug = usePageSlug()

  useEffect(() => {
    const checkHomePath = async () => {
      if (location.pathname === "/") {
        navigate(`/${wpNavigazione.singola_navigazione.elenco[0].collegamento.slug}`, { replace: true })
      }
    }

    checkHomePath()
  }, [])

  const { wpNavigazione } = useStaticQuery(graphql`
      {
          wpNavigazione {
              singola_navigazione {
                  elenco {
                      collegamento {
                          ... on WpSponsorStand {
                              id
                              slug
                          }
                          ... on WpVirtualRoom {
                              id
                              slug
                          }
                      }
                      icona {
                          localFile {
                              publicURL
                          }
                      }
                      titolo
                  }
              }
          }
      }
  `)

  return (
    <div className="docker-wrapper">
      <Row>
        <Col {...hideOnMobileColSettings}>
          <Row justify="center">
            <Col className="docker">
              <Row>
                {
                  wpNavigazione.singola_navigazione.elenco.map(({ titolo, icona, collegamento }) => (
                    <Col key={titolo} className={slug === collegamento.slug ? "docker-item active" : "docker-item"}>
                      <Link to={`/${collegamento.slug}`}>
                        <div>
                          <img src={icona.localFile.publicURL} alt={titolo} />
                        </div>
                        <div>
                          <span>{titolo}</span>
                        </div>
                      </Link>
                    </Col>
                  ))
                }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Docker
