import React from "react"
import { Col, Row, Spin } from "antd"

const LoadingScreen = () => {
  return (
    <Row justify="center">
      <Col>
        <Spin spinning={true} size="large" />
      </Col>
    </Row>
  )
}

export default LoadingScreen
